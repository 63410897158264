<script setup lang="ts">
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import {ref} from "vue";

const props = defineProps<{
    canResetPassword?: boolean;
    status?: string;
}>();

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const showPassword = ref(false);

const submit = () => {
    form.post(route('login'), {
        onFinish: () => {
            form.reset('password');
        },
    });
};
</script>

<template>
    <GuestLayout>
        <Head :title="$t('Connexion')"/>

        <v-alert type="info" v-if="status" class="mb-4">
            {{ status }}
        </v-alert>

        <v-btn block :href="route('sso.microsoft')" class="mb-6" :disabled="form.processing">
            {{ $t('Se connecter avec Microsoft') }}
        </v-btn>

        <v-divider></v-divider>

        <v-form @submit.prevent="submit">
            <p class="my-4">Se connecter par email/mot de passe</p>
            <v-text-field type="email"
                          v-model="form.email"
                          autofocus
                          autocomplete="username"
                          required
                          :label="$t('Email')"
                          :error-messages="form.errors.email as string"
                          class="mb-2"
            ></v-text-field>

            <v-text-field :type="showPassword ? 'text' : 'password'"
                          :append-inner-icon="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                          @click:append-inner="showPassword = !showPassword"
                          v-model="form.password"
                          autofocus
                          autocomplete="current-password"
                          required
                          :label="$t('Mot de passe')"
                          :error-messages="form.errors.password as string"
                          class="mb-2"
            ></v-text-field>

            <v-checkbox name="remember" v-model="form.remember" :label="$t('Se souvenir de moi')"></v-checkbox>

            <div class="d-flex justify-space-between align-center">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                >
                    {{ $t('Mot de passe oublié ?') }}
                </Link>

                <v-btn :disabled="form.processing" color="primary" type="submit">
                    {{ $t('Connexion') }}
                </v-btn>
            </div>
        </v-form>
    </GuestLayout>
</template>
